import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { HomePage } from 'features/homepage';
import type { Locale } from 'features/localization';
import type { PostNode } from 'types/post';

interface DataType {
  allBlogPost: {
    edges: { node: PostNode }[];
  };
}

function IndexPage({
  data: {
    allBlogPost: { edges },
  },
  pageContext: { locale },
}: PageProps<DataType, { locale: Locale }>) {
  const localizedPosts = edges
    .filter(({ node }) => node.frontmatter.locale === locale)
    .slice(0, 3)
    .map(({ node }) => node);
  const defaultPosts = edges
    .filter(
      ({ node }) => !localizedPosts.some(p => p.frontmatter.urls.en === node.frontmatter.urls.en)
    )
    .map(({ node }) => node);
  const featuredPosts = [...localizedPosts, ...defaultPosts].slice(0, 3).sort((a, b) => {
    const dateA = new Date(a.frontmatter.date);
    const dateB = new Date(b.frontmatter.date);
    return dateB.getTime() - dateA.getTime();
  });

  return <HomePage featuredPosts={featuredPosts} locale={locale} />;
}

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    allBlogPost(limit: 10, sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  quality: 100
                  placeholder: BLURRED
                  formats: [WEBP, AUTO]
                )
              }
            }
            postTitle {
              en
              de
            }
            author
            date
            postDescription {
              en
              de
            }
            urls {
              en
              de
            }
            locale
          }
        }
      }
    }
  }
`;
